import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import PropTypes from 'prop-types'

import SeoBreadcrumb from './seoBreadcrumb'
import SeoTwitter from './seoTwitter'
import {person, organization} from './seoDefinitions'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const Seo = ({ title, 
              excperpt, 
              imagePath,   
              locale, 
              pageContext }) => {
  const { site } = useStaticQuery(query)

  const {
    buildTime,
    siteMetadata: { 
           
            siteUrl,   
      defaultTitle,
      defaultDescription,
      defaultImage,
      headline,
      twitter
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: excperpt || defaultDescription,
    image: `${siteUrl}${imagePath || defaultImage}`,
    url: `${siteUrl}${pageContext?pageContext.breadcrumb.location:''}`,
  }

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `${siteUrl}${pageContext.breadcrumb.location}`,
    headline,
    inLanguage: locale,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: person,
    copyrightHolder: organization,
    copyrightYear: '2022',
    creator: organization,
    publisher: organization,
    datePublished: '2022-03-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultImage}`,
    },
  }

  // Initial breadcrumb list


  return (
    <>
      <Helmet title={seo.title}>
        <html lang={locale} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      </Helmet>
      <SeoBreadcrumb pageContext={pageContext}
                     title={title}/>
      <SeoTwitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  articleData: PropTypes.object,
}

Seo.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  articleData: null,
}

const query = graphql`
query SEO {
    site {
      siteMetadata {
        author
        defaultDescription: description
        keywords
        defaultTitle: title
        siteUrl
        owner
        twitterUsername
      }
      buildTime
    }
  }
` 