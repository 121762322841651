import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import { momentDE } from "../functions/functions.js";
import Markdown from "../components/Markdown.js";
import Seo from "../components/seo.js";

import * as Style from "../styles/eventsPage.module.scss";
import * as LayoutStyles from "../styles/layout.module.scss";
import { 
  Education,
  PedestrianFamily,
  GroupPresentation,
} from "@carbon/icons-react";

// markup
const EventsPage = ({ data, pageContext }) => {
  var translationContext = { locale: "de", defaultMenu: true };
  var moment = momentDE();
  var events = [];
  data.Trainings.nodes.forEach((training) => {
    training = training.frontmatter;
    training.Events?.forEach((event) => {
      var duration = event.Duration ? event.Duration : training.Duration;
      var EventType = event.EventType? event.EventType: training.EventType;
      if (new Date(event.StartDate) >= new Date())
        events.push({
          StartDate: event.StartDate,
          StartDateDisplay:
              new moment(event.StartDate).locale("de").format("dd, D.")
              +
            (duration == 1?'':
                " bis " +
                new moment(event.StartDate)
                  .add(duration - 1, "days")
                  .locale("de")
                  .format("dd, D.")),
          EventType: EventType
            ? EventType
            : "Offene Schulung",
          Location: event.Location ? event.Location : training.Location,
          Title: event.Title ? event.Title : training.Title,
          url: event.url ? event.url : "/training" + training.Slug,
          IconUrl: event.IconUrl ? event.IconUrl : training.IconUrl,
          Icon: <Education size={24} />,
        });
    });
  });
  data.Events.nodes.forEach((blogpost) => {
    var event = blogpost.frontmatter;
    if (new Date(event.StartDate) >= new Date())
      events.push({
        StartDate: event.StartDate,
        StartDateDisplay:
          new moment(event.StartDate).format("D.MM.YYYY") + ", " + event.Time,
        Title: event.title,
        url: event.url ? event.url : "/blog/" + event.slug,
        Location: event.Location,
        EventType: event.EventType,
        IconUrl: event.IconUrl,
        Icon: <GroupPresentation size={24} className={blogpost.red} />,
      });
  });

  events = events.sort((a, b) =>
    a.StartDate > b.StartDate
      ? 1
      : a.StartDate === b.StartDate
      ? a.Title > b.Title
        ? 1
        : -1
      : -1
  );
  var lastMonth = "";
  return (
    <Layout
      title={"Veranstaltungstermine"}
      pageContext={pageContext}
      translationContext={translationContext}
    >
      <div class={LayoutStyles.singleTopicLayout}>
      <Seo title={"Veranstaltungstermine"}
            locale={"de"}
            description={"Die wichtigsten Termine in den nächsten Monaten"}
            imagePath='https://www.brandeis.de/favicon.png'
            pageContext={pageContext} />
        <main>
          <h1>Veranstaltungstermine</h1>

          <div className={Style.eventList}>
            <table>
              {events.map((event) => {
                var isNewMonth =
                  lastMonth !== new moment(event.StartDate).format("YYYY-MM");
                lastMonth = new moment(event.StartDate).format("YYYY-MM");
                return (
                  <>
                    {isNewMonth ? (
                      <tr className={Style.dateCell}>
                        <td
                          className={Style.dateCell}
                          style={{ minWidth: "10em" }}
                        >
                          <b>
                            {" "}
                            {new moment(event.StartDate).format("MMMM YYYY")}
                          </b>
                        </td>
                        <td className={Style.dateCell}></td>
                        <td className={Style.dateCell}></td>
                        <td className={Style.dateCell}></td>
                        <td className={Style.dateCell}></td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>{event.StartDateDisplay}</td>
                      <td>
                        {event.IconUrl ? (
                          <img
                            src={event.IconUrl}
                            style={{ maxWidth: "50px", maxHeight: "25px" }}
                            alt={event.Title}
                          />
                        ) : (
                          event.Icon
                        )}
                      </td>
                      <td>
                        <a href={event.url}>{event.Title}</a>
                      </td>
                      <td>
                        <Markdown>{event.EventType}</Markdown>
                      </td>
                      <td>{event.Location}</td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        </main>
      </div>
    </Layout>
  );
};
export var query = graphql`
  query events_de {
    Trainings: allMdx(filter: { frontmatter: { Type: { eq: "Training" } } }) {
      nodes {
        frontmatter {
          Excerpt
          Title
          Slogan
          Slug
          Locale
          cssClass
          Prio
          Duration
          Level
          Location
          EventType
          IconUrl
          Events {
            StartDate
            Location
            Title
            url
            Duration
            IconUrl
            EventType
          }
          Image {
            childImageSharp {
              gatsbyImageData(width: 840, height: 400)
            }
          }
        }
      }
    }
    Events: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { Type: { eq: "Event" } } }
    ) {
      nodes {
        frontmatter {
          title
          StartDate: eventDate
          EventType
          Time
          Location
          url
        }
        slug
      }
    }
  }
`;

export default EventsPage;
